.form-filters{
	margin-bottom: .75rem;
	
	.form-group{
		margin-right: .75rem;
		
		label{
			margin-right: .75rem;			
		}
	}
	
	button.btn{
		margin-top: 29px
	}
}
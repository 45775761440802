// 
// tables.scss
//

//Table centered (Custom)
.table-centered {
    th, td {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
    .action-icon {
        color: $gray-600;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0 3px;

        &:hover {
            color: $gray-700;
        }
    }

    tr{
	    &.odd{ background-color: #f1f3fa }
	    &.disabled{ opacity: .4; pointer-events: none }
    }
}

@media screen and (max-width:800px) {
	.table-custom-responsive{
		td:before { content: attr(data-title); }
		
		table, thead, tbody, th, td, tr { 
			display: block; 
		}
		 
		thead tr { 
			display:none;
		}
		td { 
			position: relative;
			padding-left: 50% !important; 
			white-space: normal;
		}
		tr{ border-bottom: 4px solid #e3eaef }
		 
		td:before { 
			position: absolute;
			top: 0px;
			left: 0px;
			width: 45%; 
			height:100%;
			white-space: nowrap;
			text-overflow: ellipsis !important;
			overflow:hidden !important;
			text-align:left;
			background-color:#f8f8f8;
			padding:.95rem;
		}
	}
}